.over {
 display: grid !important;
 grid-template-columns: 1fr 1fr 1fr !important;
 gap: 10px;
 /* border: 2px solid green; */
}

@media only screen and (max-width: 600px) {
 .over {
  display: grid !important;
  grid-template-columns: 1fr !important;
  gap: 10px;
  place-items: center;
  place-content: center;
  width: 100%;
 }
}