@import ../../../../styles/helpers

.chart
    width: auto
    height: 329px
    margin-left: -20px
    margin-bottom: -10px
    +t
        height: 300px

