@import ../../../styles/helpers

.wrapper
    background: $n1
    border-radius: 8px
    +dark
        background: $n7

.table
    display: table
    width: 100%

.row
    display: table-row
    &:first-child
        .col
            padding-top: 24px
            padding-bottom: 24px
            +caption2
            font-weight: 500
            color: $n4
            +m
                padding: 16px
    &:nth-child(2n)
        .col
            background: rgba($n3, .35)
            +dark
                background: rgba($n8, .5)

.col
    display: table-cell
    padding: 16px
    white-space: nowrap
    +d
        padding: 16px 8px
    +m
        padding-left: 16px
    &:first-child
        padding-left: 24px
        color: $n4
        +d
            font-size: 13px
        +m
            padding-left: 16px
    &:last-child
        padding-right: 24px 
    &:nth-child(3n+1)
        +m
            display: none

.tooltip
    position: relative
    top: -1px
    svg
        fill: $shades1  