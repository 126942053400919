@import ../../../styles/helpers

.head
    +m
        display: block

.title
    +m
        margin-bottom: 16px

.chart
    width: auto
    height: 250px
    margin-left: -35px
    margin-bottom: -10px