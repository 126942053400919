@import ../../../styles/helpers

.creator
    padding-bottom: 24px
    border-bottom: 1px solid $n3
    +dark
        border-color: $n6

.head
    display: flex
    align-items: center

.user
    display: flex
    align-items: center
    margin-right: auto

.avatar
    flex-shrink: 0
    width: 80px
    height: 80px
    margin-right: 16px
    +m
        width: 64px
        height: 64px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.line
    display: flex
    align-items: center
    margin-bottom: 4px

.man
    +title1-s

.number
    margin-left: 6px
    padding: 4px 8px
    border-radius: 6px
    font-weight: 700
    +dark
        color: $n7

.parameters
    display: flex
    margin-left: -12px

.parameter
    margin-left: 12px
    +caption1
    color: $shades1
    span
        color: $n4
    &:not(:first-child)
        position: relative
        padding-left: 14px
        &:before
            content: ""
            position: absolute
            top: 5px
            left: 0
            width: 2px
            height: 8px
            border-radius: 2px
            background: $n3
            +dark
                background: $n6

.btns
    display: flex
    margin-left: 24px
    +m
        display: none
    .button
        span
            display: none
        &:not(:last-child)
            margin-right: 8px
        &:first-child
            &.active
                background: $n3
                box-shadow: inset 0 0 0 3px $n3
                span
                    display: inline

.products
    display: flex
    margin: 24px -24px 0
    overflow: auto
    overflow-x: auto 
    -ms-overflow-style: none
    scrollbar-width: none
    -webkit-overflow-scrolling: touch
    &::-webkit-scrollbar
        display: none 
    &:before,
    &:after
        content: ""
        display: block
        flex-shrink: 0
        width: 24px
        height: 1px

.product
    position: relative
    flex-shrink: 0
    width: 212px
    height: 166px
    border-radius: 12px
    overflow: hidden
    cursor: pointer
    img
        width: 100%
        height: 100%
        object-fit: cover
    &:before
        content: ""
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: rgba($n8,.8) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' viewBox='0 0 36 36'%3E%3Crect width='36' height='36' rx='18' fill='%23fcfcfc'/%3E%3Cpath d='M19.077 22.827c-.325.325-.325.853 0 1.178s.853.325 1.178 0l4.827-4.827c.651-.651.651-1.706 0-2.357l-4.827-4.827c-.325-.325-.853-.325-1.178 0s-.325.853 0 1.178l3.994 3.994H11.333c-.46 0-.833.373-.833.833s.373.833.833.833h11.738l-3.994 3.994z' fill='%236f767e'/%3E%3C/svg%3E") no-repeat 50% 50% / 36px auto
        opacity: 0
        visibility: hidden
        transition: all .2s
    &:hover
        &:before
            visibility: visible
            opacity: 1
    &:not(:last-child)
        margin-right: 12px

