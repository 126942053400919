@import ../../../styles/helpers

.item
    display: flex
    &:not(:last-child)
        margin-bottom: 32px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +m
            margin-bottom: 24px
        +dark
            border-color: $n6

.avatar
    flex-shrink: 0
    width: 48px
    height: 48px
    margin-right: 12px
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.details
    flex-grow: 1

.line
    display: flex
    align-items: center

.user
    margin-right: auto

.title
    font-weight: 700

.login
    font-weight: 500
    color: $shades1

.time
    margin-left: 20px
    +caption1
    color: $shades1

.content
    font-weight: 500
    color: $n7
    +dark
        color: $n1

.button
    width: 100%
    margin-top: 32px
