/* src/CustomTooltip.css */
.custom-tooltip-wrapper {
  position: relative;
  display: inline-block;
  margin-left: 3px;
}

.custom-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
}
