@import ../../../../../styles/helpers

.cardHead
    +m
        display: block

.cardTitle
    +m
        margin-bottom: 16px

.chart
    width: auto
    height: 244px
    margin-left: -25px
    margin-bottom: -10px

