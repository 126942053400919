@import ../../../../styles/helpers

.panel
    padding: 24px
    border-radius: 12px 12px 0 0
    background: $n2
    +t
        background: none
        box-shadow: inset 0 -1px 0 0 $n3
    +m
        padding: 16px
    +dark
        background: $n6
        +t
            background: none
            box-shadow: inset 0 -1px 0 0 $n6

.line
    display: flex
    align-items: center

.man
    margin-right: auto
    +title1-s

.close
    display: none
    width: 40px
    height: 40px
    border-radius: 50%
    margin-left: 12px
    +t
        display: inline-block
    svg
        fill: $n4
    +dark
        +t
            background: $n6

.parameters
    display: flex
    flex-wrap: wrap
    margin-left: -12px
    +w
        margin-top: 8px
    +t
        display: none

.parameter
    margin-left: 12px
    +caption1
    font-weight: 500
    color: $n4
    span
        font-weight: 700
        color: $n5
        +dark
            color: $n1
    &:not(:first-child)
        position: relative
        padding-left: 14px
        &:before
            content: ""
            position: absolute
            top: 2px
            left: 0
            width: 2px
            height: 12px
            border-radius: 2px
            background: rgba($n4, .51)

.actionsHead
    background: $n1 !important
    +dark
        background: $shades4 !important
        +t
            background: $n6 !important

.actionsBody:before
    +dark
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E")
        +t
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23272B30'/%3E%3C/svg%3E")
