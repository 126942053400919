@import ../../styles/helpers

.item
    display: flex
    &:not(:last-child)
        margin-bottom: 12px

.icon
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 48px
    height: 48px
    border-radius: 50%
    margin-right: 12px

.content
    font-weight: 500
    color: $n4
    strong
        font-weight: 600
        color: $n7
        +dark
            color: $n1

.button
    width: 100%
    margin-top: 24px
