.coverring-stage {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}
@media only screen and (max-width: 600px) {
  .coverring-stage {
    display: grid !important;
    grid-template-columns: 1fr !important;
    gap: 10px;
    place-items: center;
    place-content: center;
    width: 100%;
  }
}
