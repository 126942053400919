@import ../../../styles/helpers

.chart
    width: auto
    height: 320px
    margin-left: -35px
    margin-bottom: -10px

.legend
    display: flex
    justify-content: space-between
    margin-top: 32px
    +m
        display: none

.indicator
    display: flex
    align-items: center
    +caption2
    font-weight: 700

.color
    flex-shrink: 0
    width: 16px
    height: 16px
    margin-right: 8px
    border-radius: 4px