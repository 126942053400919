@import ../../../../styles/helpers

.row
    position: relative
    display: flex
    padding: 16px 12px
    border-radius: 8px
    transition: background .2s
    +t
        display: block
        margin-bottom: 16px
        padding: 0 0 16px
        border-radius: 0
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:after
        content: ""
        position: absolute
        left: 12px
        bottom: 0
        right: 12px
        height: 1px
        background: $n3
        +t
            display: none
        +dark
            background: $n6
    &:hover
        +rmin(1260)
            background: $n3
            +dark
                background: $n6
            .control
                visibility: visible
                opacity: 1
    &.active
        +rmin(1024)
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05)
            background: $n
            +dark
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                background: $n6

.col
    &:first-child
        flex-shrink: 0
        width: 24px
        font-size: 0
        +t
            display: none
    &:nth-child(2)
        flex-grow: 1
        padding: 0 32px
        +t
            padding: 0
    &:last-child
        flex-shrink: 0
        width: 268px
        +t
            display: none

.item
    display: flex
    align-items: center

.preview
    flex-shrink: 0
    width: 80px
    height: 80px
    margin-right: 20px
    img
        width: 100%
        height: 100%
        border-radius: 8px
        object-fit: cover

.product
    margin-bottom: 4px
    font-weight: 700
    color: $n7
    +dark
        color: $n1

.category
    +caption1
    color: $shades1

.box
    display: flex

.avatar
    position: relative
    flex-shrink: 0
    width: 48px
    height: 48px
    margin-right: 20px
    +m
        margin-right: 12px
    & > img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.details
    flex-grow: 1

.line
    display: flex
    align-items: center
    margin-bottom: 4px

.author
    margin-right: auto
    font-weight: 700

.time
    margin-left: 24px
    +caption1
    color: $shades1

.comment
    color: $n5
    +dark
        color: $n1

.control
    visibility: hidden
    opacity: 0
    transition: all .2s
    +d
        visibility: visible
        opacity: 1

