@import ../../../styles/helpers

.cardHead
    +m
        display: block

.cardTitle
    +m
        margin-bottom: 16px

.details
    margin-bottom: 32px
    +m
        margin-bottom: 24px
        text-align: center

.title
    margin-bottom: 4px

.line
    display: flex
    align-items: center
    +caption2
    font-weight: 700
    color: $shades1
    +m
        justify-content: center

.balance
    margin-right: 4px

.users
    margin-top: 32px
    padding-top: 32px
    border-top: 1px solid $n3
    +m
        margin-top: 24px
        padding-top: 24px
    +dark
        border-color: $n6