@import ../../styles/helpers

.head
    margin-bottom: 44px
    +t
        margin-bottom: 32px
    +m
        flex-wrap: wrap
        margin-bottom: 24px
    .form
        +t
            width: 320px
        +m
            width: 100%

.title
    margin-right: 24px
    +t
        margin-right: auto
    +m
        margin: 0 0 16px
