.custom-datepicker-input {
  padding: 8px;
  border-radius: 15px;
  margin-left: 2rem;
}
.mode-dark {
  background-color: #1a1d1f;
  border: 2px solid #272b30;
}

.mode-light {
  background-color: white;
  border: 3px solid #efefef;
}

.dark-mode .custom-datepicker-input {
  color: #6f767e;
  font-weight: bold;
  width: 7rem;
  height: 2.8rem;
}

.light-mode .custom-datepicker-input {
  color: #1a1d1f;
  font-weight: bold;
  width: 7rem;
  height: 2.8rem;
}
