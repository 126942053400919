@import ../../../styles/helpers

.group
    display: flex
    flex-direction: column

.checkbox,
.radio
    &:not(:last-child)
        margin-bottom: 16px

.btns
    display: flex
    margin: 32px -6px 0
    .button
        width: calc(50% - 12px)
        flex: 0 0 calc(50% - 12px)
        margin: 0 6px

.variants
    display: flex
    flex-direction: column
    margin-top: 32px
    padding-top: 32px
    border-top: 1px solid $n3
    +dark
        border-color: $n6
       