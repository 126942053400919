@import ../../../../styles/helpers

.chart
    width: auto
    height: 238px
    margin-left: -20px
    margin-bottom: -10px
    +m
        height: 206px

