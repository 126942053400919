@import ../../styles/helpers

.wrapper
    display: flex
    height: 776px
    padding: 12px
    border-radius: 8px
    background: $n1
    +w
        height: 700px
    +t
        display: block
        height: calc(100vh - 218px)
        padding: 0
        &.active
            .users
                display: none
            .messages
                display: block
    +m
        height: calc(100vh - 184px)
    +dark
        background: $n7

.users
    flex-shrink: 0
    width: 340px
    padding: 12px
    +d
        width: 310px
    +t
        width: 100%
        height: 100%
        padding: 24px 32px
    +m
        padding: 16px

.messages
    +t
        display: none

