.home {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid white; */
  width: 100%;
  height: 100%;
  background-image: url('https://cross-river-pay.s3.us-east-2.amazonaws.com/cas-admin/images/content/Calabar-Sign-Roundabout.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.input-field {
  /* border: 2px solid blue; */
  width: 70% !important;
  margin-bottom: 10px;
}

.input-wrap {
  /* border: 2px solid blue; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* di */
}

.btn-bb {
  width: 70% !important;
}
.left .input-wrap {
  /* border: 2px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}
@media only screen and (max-width: 600px) {
  .home {
    display: grid;
    grid-template-columns: 1fr;
  }
  .btn-bb{
    width: 100% !important;
  }
  .input-field{
    width: 100% !important;
  }
}


/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {...} */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
  .btn-bb{
    width: 100% !important;
  }
  .input-field{
    width: 100% !important;
  }
} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {
  .btn-bb{
    width: 100% !important;
  }
  .input-field{
    width: 100% !important;
  }
} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */
