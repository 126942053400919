@import ../../../../../styles/helpers

.list
    display: flex
    justify-content: space-between
    +t
        display: block

.item
    display: block
    padding: 0 32px 0 64px
    color: $n7
    +x
        padding: 0 32px 0 48px
    +d
        padding: 0 32px
    +t
        display: flex
        padding: 0
    +dark
        color: $n1
    &:not(:first-child)
        border-width: 0 0 0 1px
        border-style: solid
        border-color: $n3
        +t
            margin-top: 16px
            padding-top: 16px
            border-width: 1px 0 0
        +dark
            border-color: $n6
    &:first-child
        padding-left: 0
    &:last-child
        padding-right: 0

.icon
    display: flex
    justify-content: center
    align-items: center
    width: 48px
    height: 48px
    margin-bottom: 24px
    border-radius: 50%
    +t
        margin: 0 16px 0 0
    svg
        fill: $n7

.label
    display: flex
    align-items: center
    margin-bottom: 8px
    +caption1
    color: $n4

.tooltip
    svg
        fill: $n4

.counter
    min-width: 238px
    font-size: 48px
    font-weight: 600
    line-height: 1
    letter-spacing: -.03em
    +t
        min-width: auto
        font-size: 40px
    +m
        font-size: 32px
